import React from 'react';
import { navigate } from 'gatsby';

import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import contentOmit from '@/others/ommitted';

type PreviousButtonProps = {
  title: string;
  url: string;
};

const PreviousButton: React.VFC<PreviousButtonProps> = ({ title, url }) => {
  const handleClick = () => navigate(`/news/${url}`);

  return (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      fullWidth
      startIcon={<ArrowBackIosIcon />}
      onClick={handleClick}
    >
      {contentOmit(title, 12)}
    </Button>
  );
};

export default PreviousButton;
