import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core';

type FirstViewImageProps = {
  image: any;
};

const useStyles = makeStyles({
  FirstViewImageBox: {
    position: `relative`,
    width: `100%`,
    height: `30vh`,
    margin: `1rem auto`,
  },
  FirstViewImageStyle: {
    zIndex: 1,
    position: `absolute`,
    left: 0,
    width: `80%`,
    minWidth: `54vw`,
    height: `30vh`,
    borderRadius: `0 15vh 15vh 0`,
    boxShadow: `0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)`,
  },
});

const FirstViewImage: React.VFC<FirstViewImageProps> = ({ image }) => {
  const classes = useStyles();

  return (
    <div className={classes.FirstViewImageBox}>
      <GatsbyImage
        image={image}
        alt="eye catch"
        className={classes.FirstViewImageStyle}
        objectPosition="50% 20%"
      />
    </div>
  );
};

export default FirstViewImage;
