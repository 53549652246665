import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

type StaticSEOProps = {
  titleData?: string;
  descriptionData?: string;
  OgImageData?: any;
};

const Meta: React.VFC<StaticSEOProps> = ({
  titleData,
  descriptionData,
  OgImageData,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  const title = titleData || defaults.title;
  const description = descriptionData || defaults.description;
  const image = OgImageData || false;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* fixMe: fix path after deploy */}
      {image && <meta property="og:image" content={image} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="tiwitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* fixMe: fix path after deploy */}
      {image && <meta name="twitter:image" content={image} />}
      {/* fixMe: add google key after deploy */}
      {/* top && <meta
        name="google-site-verification"
        content={process.env.GATSBY_GOOGLE_SITE_VERIFICATION_KEY}
      /> */}
    </Helmet>
  );
};

export default Meta;
