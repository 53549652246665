import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { orange } from '@material-ui/core/colors';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/template/section/FirstView';
import BottomMenu from '@/components/common/section/BottomMenu';
import PreviousButton from '@/components/template/basic/PreviousButton';
import NextButton from '@/components/template/basic/NextButton';
import TemplateSEO from '@/components/template/section/TemplateSEO';

type NewsPostTemplateProps = {
  pageContext: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    articleBox: {
      padding: `1rem 0`,
      background: `linear-gradient(180deg, ${orange[500]} 0%, ${orange[500]} 15%, #F7FDF9 45%, #F7FDF9 100%)`,
    },
    articlePaper: {
      margin: `0 auto`,
      width: `90%`,
      maxWidth: `1200px`,
      padding: `calc(1.5rem + 1vw)`,
    },
    articleInfoWrapper: {
      backgroundColor: orange[500],
      padding: `.5rem 0`,
    },
    articleInfoBox: {
      margin: `0 auto`,
      width: `90%`,
      maxWidth: `1200px`,
      display: `flex`,
      alignItems: `center`,
    },
    articleTagChip: {
      border: `1px solid #ffffff`,
      color: `#ffffff`,
      marginRight: `1rem`,
    },
    articleCreatedDate: {
      color: `#ffffff`,
      margin: `0 1rem`,
    },
    paraStyle: {
      margin: `.5rem auto`,
    },
    buttonWrapper: {
      margin: `1rem auto`,
      width: `90%`,
      maxWidth: `1200px`,
    },
    image: {
      margin: `1rem auto`,
    },
    entryBlock: {
      border: `3px solid #ffffff`,
      borderRadius: `6px`,
      padding: `.5rem`,
      [theme.breakpoints.up(`sm`)]: {
        display: `flex`,
        alignItems: `center`,
      },
    },
    entryBlockImage: {
      [theme.breakpoints.up(`sm`)]: {
        maxHeight: `120px`,
        maxWidth: `240px`,
      },
    },
    entryBlockLinkStyles: {
      textDecoration: `none`,
      color: `#0066c0`,
      fontWeight: 600,
    },
  }),
);

const options: any = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography style={{ margin: `.75rem auto` }}>{children}</Typography>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography variant="h2">{children}</Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography variant="h3">{children}</Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography variant="h4">{children}</Typography>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { data } = node;

      return (
        <GatsbyImage
          image={getImage(data.target)}
          alt={data.target.title}
          className={useStyles().image}
        />
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { data } = node;

      return (
        <Link
          to={`/news/${data.target.url}`}
          className={useStyles().entryBlockLinkStyles}
        >
          <div className={useStyles().entryBlock}>
            <GatsbyImage
              image={getImage(data.target.eyeCatch)}
              alt={data.target.title}
              className={useStyles().entryBlockImage}
            />
            <Typography align="center">{data.target.title}</Typography>
          </div>
        </Link>
      );
    },
  },
};

const NewsPostTemplate: React.VFC<NewsPostTemplateProps> = ({
  pageContext,
}) => {
  const { post, next, previous } = pageContext;
  const eyeCatch = getImage(post.eyeCatch);
  const classes = useStyles();

  return (
    <Layout>
      <TemplateSEO
        titleData={post.title}
        descriptionData={post.description}
        OgImageData={post.eyeCatch}
      />
      <FirstView image={eyeCatch} pageTitle={post.title} />
      <div className={classes.articleInfoWrapper}>
        <div className={classes.articleInfoBox}>
          <Chip
            variant="outlined"
            label={post.tag.title}
            className={classes.articleTagChip}
          />
          <Typography className={classes.articleCreatedDate}>
            {post.createdAt}
          </Typography>
        </div>
      </div>
      <section className={classes.articleBox}>
        <Paper elevation={4} className={classes.articlePaper}>
          {post.content && renderRichText(post.content, options)}
        </Paper>
      </section>
      <section>
        <Grid container spacing={3} className={classes.buttonWrapper}>
          <Grid item xs={12} sm={6}>
            {previous && (
              <PreviousButton title={previous.title} url={previous.url} />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {next && <NextButton title={next.title} url={next.url} />}
          </Grid>
        </Grid>
      </section>
      <BottomMenu />
    </Layout>
  );
};

export default NewsPostTemplate;
